<template>
  <div class="tax-form">
    <v-form ref="form">
      <v-row>
        <v-col cols="6">
          <v-text-field
              outlined
              dense
              class="label-input input-number"
              :label="$t('Tax.form.salaryMin')"
              :rules="nameRules"
              type="number"
              v-model="items.minimum_salary"
              :hint="`${server_errors.minimum_salary}`"
              persistent-hint
              @keydown.enter="focusNext"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
              outlined
              dense
              class="label-input input-number"
              :label="$t('Tax.form.salaryMax')"
              :rules="nameRules"
              type="number"
              v-model="items.maximum_salary"
              :hint="`${server_errors.maximum_salary}`"
              persistent-hint
              @keydown.enter="focusNext"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
              outlined
              dense
              class="label-input input-number"
              type="number"
              :label="$t('Tax.form.charge')"
              :rules="nameRules"
              v-model="items.charge"
              :hint="`${server_errors.charge}`"
              persistent-hint
              @keydown.enter="focusNext"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
              outlined
              dense
              class="label-input"
              :label="$t('Tax.form.comparison_mark')"
              :rules="nameRules"
              v-model="items.comparison_mark"
              :hint="`${server_errors.comparison_mark}`"
              persistent-hint
              @keydown.prevent.enter="validateForm"
          >
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="validateForm">
            {{ $t("Tax.form.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      items: {
        minimum_salary: "",
        charge: "",
        maximum_salary: "",
        comparison_mark: ""
      },

      server_errors: {
        minimum_salary: "",
        charge: "",
        maximum_salary: "",
        comparison_mark: ""
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input,vuetify-money'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    saveChange() {
      this.$axios.post(`admin/add/tax`, this.items).then((res) => {
        if (res.data.success === true) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
        this.$router.push({
          name: "tax.index"
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tax-form {
  width: 100%;
  height: 600px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;

}
</style>
